import { Text } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import React, { useEffect } from "react";
import { strings } from "../strings";
import { signOut, fetchAuthSession } from "aws-amplify/auth";
import BasicLayout from "../components/basicLayout";
import { useNavigate } from "react-router-dom";

I18n.putVocabularies(strings);

const Logout = () => {
	useEffect(async () => {
		const session = await fetchAuthSession();

		if (session?.tokens?.idToken) {
			signOut()
				.then(() => {
					const queryParams = new URLSearchParams(
						window.location.search
					);
					const redirect_uri = queryParams.get("redirect_uri");
					window.location.href = redirect_uri;
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			const queryParams = new URLSearchParams(window.location.search);
			const redirect_uri = queryParams.get("redirect_uri");
			window.location.href = redirect_uri || "/";
		}
	}, []);

	return (
		<BasicLayout>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				<Text
					headingLevel={1}
					marginBottom={"20px"}
					textAlign={"center"}
				>
					{"Logging out..."}
				</Text>
			</div>
		</BasicLayout>
	);
};

export default Logout;
