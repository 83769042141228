export const languages = [
	{
		value: "it",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3730)">
					<path
						d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
						fill="#F0F0F0"
					/>
					<path
						d="M23.9999 11.9992C23.9999 6.83958 20.7434 2.44106 16.1738 0.745544V23.2528C20.7434 21.5572 23.9999 17.1587 23.9999 11.9992Z"
						fill="#D80027"
					/>
					<path
						d="M0 11.9997C0 17.1593 3.2565 21.5578 7.82611 23.2533V0.746094C3.2565 2.44161 0 6.84013 0 11.9997Z"
						fill="#6DA544"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3730">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "Italian",
	},
	{
		value: "us/ca",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3723)">
					<path
						d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
						fill="#F0F0F0"
					/>
					<path
						d="M11.4775 12H23.9993C23.9993 10.9169 23.8549 9.86761 23.5859 8.86951H11.4775V12Z"
						fill="#D80027"
					/>
					<path
						d="M11.4775 5.73848H22.238C21.5034 4.5398 20.5642 3.48028 19.4691 2.60803H11.4775V5.73848Z"
						fill="#D80027"
					/>
					<path
						d="M12.0001 23.9999C14.8243 23.9999 17.4201 23.0238 19.4699 21.3912H4.53027C6.58012 23.0238 9.17591 23.9999 12.0001 23.9999Z"
						fill="#D80027"
					/>
					<path
						d="M1.76098 18.2598H22.2384C22.8281 17.2975 23.2855 16.2457 23.5863 15.1294H0.413086C0.713883 16.2457 1.17124 17.2975 1.76098 18.2598Z"
						fill="#D80027"
					/>
					<path
						d="M5.55863 1.87397H6.65217L5.63498 2.61295L6.02353 3.80869L5.00639 3.0697L3.98925 3.80869L4.32487 2.7757C3.42928 3.52172 2.64431 4.39575 1.99744 5.36963H2.34783L1.70034 5.84002C1.59947 6.0083 1.50272 6.17925 1.41 6.35273L1.71919 7.30434L1.14234 6.88523C0.998953 7.18903 0.867797 7.49967 0.749906 7.81678L1.09055 8.86528H2.34783L1.33064 9.60427L1.71919 10.8L0.702047 10.061L0.0927656 10.5037C0.0317812 10.9939 0 11.4932 0 12H12C12 5.37262 12 4.59131 12 0C9.62944 0 7.41961 0.687656 5.55863 1.87397ZM6.02353 10.8L5.00639 10.061L3.98925 10.8L4.3778 9.60427L3.36061 8.86528H4.61789L5.00639 7.66955L5.39489 8.86528H6.65217L5.63498 9.60427L6.02353 10.8ZM5.63498 6.10861L6.02353 7.30434L5.00639 6.56536L3.98925 7.30434L4.3778 6.10861L3.36061 5.36963H4.61789L5.00639 4.17389L5.39489 5.36963H6.65217L5.63498 6.10861ZM10.3279 10.8L9.31073 10.061L8.29359 10.8L8.68214 9.60427L7.66495 8.86528H8.92223L9.31073 7.66955L9.69923 8.86528H10.9565L9.93933 9.60427L10.3279 10.8ZM9.93933 6.10861L10.3279 7.30434L9.31073 6.56536L8.29359 7.30434L8.68214 6.10861L7.66495 5.36963H8.92223L9.31073 4.17389L9.69923 5.36963H10.9565L9.93933 6.10861ZM9.93933 2.61295L10.3279 3.80869L9.31073 3.0697L8.29359 3.80869L8.68214 2.61295L7.66495 1.87397H8.92223L9.31073 0.678234L9.69923 1.87397H10.9565L9.93933 2.61295Z"
						fill="#0052B4"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3723">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "American",
	},
	{
		value: "gb",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3722)">
					<path
						d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
						fill="#F0F0F0"
					/>
					<path
						d="M2.48032 4.69299C1.53771 5.91938 0.826945 7.3329 0.413086 8.86848H6.6558L2.48032 4.69299Z"
						fill="#0052B4"
					/>
					<path
						d="M23.5864 8.86855C23.1725 7.33302 22.4617 5.91951 21.5191 4.69312L17.3438 8.86855H23.5864Z"
						fill="#0052B4"
					/>
					<path
						d="M0.413086 15.1302C0.826992 16.6658 1.53776 18.0793 2.48032 19.3056L6.65567 15.1302H0.413086Z"
						fill="#0052B4"
					/>
					<path
						d="M19.3053 2.47976C18.0789 1.53715 16.6655 0.826382 15.1299 0.412476V6.65515L19.3053 2.47976Z"
						fill="#0052B4"
					/>
					<path
						d="M4.69336 21.5176C5.91975 22.4602 7.33327 23.171 8.8688 23.5849V17.3423L4.69336 21.5176Z"
						fill="#0052B4"
					/>
					<path
						d="M8.86875 0.412476C7.33322 0.826382 5.9197 1.53715 4.69336 2.47971L8.86875 6.6551V0.412476Z"
						fill="#0052B4"
					/>
					<path
						d="M15.1299 23.5849C16.6654 23.171 18.0789 22.4602 19.3053 21.5177L15.1299 17.3423V23.5849Z"
						fill="#0052B4"
					/>
					<path
						d="M17.3438 15.1302L21.5191 19.3057C22.4617 18.0793 23.1725 16.6658 23.5864 15.1302H17.3438Z"
						fill="#0052B4"
					/>
					<path
						d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
						fill="#D80027"
					/>
					<path
						d="M15.1309 15.1313L20.4857 20.4861C20.732 20.2399 20.9669 19.9825 21.191 19.7157L16.6066 15.1312H15.1309V15.1313Z"
						fill="#D80027"
					/>
					<path
						d="M8.86951 15.1312H8.86941L3.51465 20.486C3.76084 20.7323 4.01823 20.9672 4.28504 21.1914L8.86951 16.6068V15.1312Z"
						fill="#D80027"
					/>
					<path
						d="M8.86976 8.86911V8.86902L3.51495 3.51416C3.26866 3.76035 3.03373 4.01774 2.80957 4.28455L7.39409 8.86907H8.86976V8.86911Z"
						fill="#D80027"
					/>
					<path
						d="M15.1309 8.87017L20.4857 3.51526C20.2395 3.26898 19.9821 3.03405 19.7153 2.80994L15.1309 7.39445V8.87017Z"
						fill="#D80027"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3722">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "English",
	},
	{
		value: "fr",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3623)">
					<path
						d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
						fill="#F0F0F0"
					/>
					<path
						d="M23.9999 11.9999C23.9999 6.84037 20.7434 2.44185 16.1738 0.746338V23.2536C20.7434 21.558 23.9999 17.1595 23.9999 11.9999Z"
						fill="#D80027"
					/>
					<path
						d="M0 12.0008C0 17.1604 3.25655 21.5589 7.82611 23.2544V0.747192C3.25655 2.44271 0 6.84122 0 12.0008Z"
						fill="#0052B4"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3623">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "French",
	},
	{
		value: "de",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3728)">
					<path
						d="M0.746094 16.1749C2.44161 20.7445 6.84012 24.001 11.9997 24.001C17.1593 24.001 21.5578 20.7445 23.2533 16.1749L11.9997 15.1315L0.746094 16.1749Z"
						fill="#FFDA44"
					/>
					<path
						d="M11.9997 0.000793457C6.84012 0.000793457 2.44161 3.25729 0.746094 7.8269L11.9997 8.87034L23.2533 7.82686C21.5578 3.25729 17.1593 0.000793457 11.9997 0.000793457Z"
						fill="black"
					/>
					<path
						d="M0.746391 7.82574C0.264047 9.12573 0 10.5318 0 11.9996C0 13.4675 0.264047 14.8735 0.746391 16.1735H23.2537C23.736 14.8735 24 13.4675 24 11.9996C24 10.5318 23.736 9.12573 23.2536 7.82574H0.746391Z"
						fill="#D80027"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3728">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "German",
	},
	{
		value: "hu",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3640)">
					<path
						d="M0.746391 7.82522C0.264047 9.12525 0 10.5313 0 11.9991C0 13.467 0.264047 14.8731 0.746391 16.173L12 17.2165L23.2536 16.173C23.736 14.8731 24 13.467 24 11.9991C24 10.5313 23.736 9.12525 23.2536 7.82522L12 6.78174L0.746391 7.82522Z"
						fill="#F0F0F0"
					/>
					<path
						d="M12.0006 -0.000976562C6.8411 -0.000976562 2.44254 3.25552 0.74707 7.82513H23.2543C21.5587 3.25552 17.1603 -0.000976562 12.0006 -0.000976562Z"
						fill="#D80027"
					/>
					<path
						d="M12.0006 23.9993C17.1603 23.9993 21.5587 20.7428 23.2543 16.1732H0.74707C2.44254 20.7428 6.8411 23.9993 12.0006 23.9993Z"
						fill="#6DA544"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3640">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "Hungarian",
	},
	{
		value: "ro",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3695)">
					<path
						d="M16.1745 0.745367C14.8744 0.26307 13.4684 -0.000976556 12.0006 -0.000976556C10.5327 -0.00102343 9.12667 0.26307 7.82669 0.745367L6.7832 11.999L7.82669 23.2526C9.12662 23.735 10.5327 23.999 12.0006 23.999C13.4684 23.999 14.8745 23.735 16.1745 23.2526L17.2179 11.999L16.1745 0.745367Z"
						fill="#FFDA44"
					/>
					<path
						d="M23.9999 11.9996C23.9999 6.84005 20.7434 2.44144 16.1738 0.745972V23.2533C20.7434 21.5576 23.9999 17.1592 23.9999 11.9996Z"
						fill="#D80027"
					/>
					<path
						d="M0 11.9995C0 17.1592 3.2565 21.5576 7.82602 23.2532L7.82606 0.745972C3.2565 2.44144 0 6.83991 0 11.9995Z"
						fill="#0052B4"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3695">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "Romanian",
	},
	{
		value: "es",
		icon: (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1046_3708)">
					<path
						d="M0 11.9992C0 13.4671 0.264047 14.8731 0.746391 16.1731L12 17.2166L23.2536 16.1731C23.736 14.8731 24 13.4671 24 11.9992C24 10.5314 23.736 9.12533 23.2536 7.82534L12 6.78186L0.746391 7.82534C0.264047 9.12533 0 10.5314 0 11.9992Z"
						fill="#FFDA44"
					/>
					<path
						d="M23.2553 7.82574C21.5597 3.25618 17.1612 -0.000366211 12.0017 -0.000366211C6.84208 -0.000366211 2.44356 3.25618 0.748047 7.82574H23.2553Z"
						fill="#D80027"
					/>
					<path
						d="M0.74707 16.174C2.44259 20.7435 6.8411 24.0001 12.0007 24.0001C17.1603 24.0001 21.5588 20.7435 23.2543 16.174H0.74707Z"
						fill="#D80027"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1046_3708">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		),
		label: "Spanish",
	},
];

export const roles = [
	{
		label: "Sign up role option centro-assistenza",
		value: "centro-assistenza",
	},
	{
		label: "Sign up role option installatore",
		value: "installatore",
	},
	{
		label: "Sign up role option progettista",
		value: "progettista",
	},
	{
		label: "Sign up role option rivenditore",
		value: "rivenditore",
	},
	{
		label: "Sign up role option privato",
		value: "privato",
	},
];

export const provinces = [
	{ label: "Agrigento", value: "AG" },
	{ label: "Alessandria", value: "AL" },
	{ label: "Ancona", value: "AN" },
	{ label: "Aosta", value: "AO" },
	{ label: "Arezzo", value: "AR" },
	{ label: "Ascoli Piceno", value: "AP" },
	{ label: "Asti", value: "AT" },
	{ label: "Avellino", value: "AV" },
	{ label: "Bari", value: "BA" },
	{ label: "Barletta-Andria-Trani", value: "BT" },
	{ label: "Belluno", value: "BL" },
	{ label: "Benevento", value: "BN" },
	{ label: "Bergamo", value: "BG" },
	{ label: "Biella", value: "BI" },
	{ label: "Bologna", value: "BO" },
	{ label: "Bolzano", value: "BZ" },
	{ label: "Brescia", value: "BS" },
	{ label: "Brindisi", value: "BR" },
	{ label: "Cagliari", value: "CA" },
	{ label: "Caltanissetta", value: "CL" },
	{ label: "Campobasso", value: "CB" },
	{ label: "Carbonia-Iglesias", value: "CI" },
	{ label: "Caserta", value: "CE" },
	{ label: "Catania", value: "CT" },
	{ label: "Catanzaro", value: "CZ" },
	{ label: "Chieti", value: "CH" },
	{ label: "Como", value: "CO" },
	{ label: "Cosenza", value: "CS" },
	{ label: "Cremona", value: "CR" },
	{ label: "Crotone", value: "KR" },
	{ label: "Cuneo", value: "CN" },
	{ label: "Enna", value: "EN" },
	{ label: "Fermo", value: "FM" },
	{ label: "Ferrara", value: "FE" },
	{ label: "Firenze", value: "FI" },
	{ label: "Foggia", value: "FG" },
	{ label: "Forlì-Cesena", value: "FC" },
	{ label: "Frosinone", value: "FR" },
	{ label: "Genova", value: "GE" },
	{ label: "Gorizia", value: "GO" },
	{ label: "Grosseto", value: "GR" },
	{ label: "Imperia", value: "IM" },
	{ label: "Isernia", value: "IS" },
	{ label: "La Spezia", value: "SP" },
	{ label: "L'Aquila", value: "AQ" },
	{ label: "Latina", value: "LT" },
	{ label: "Lecce", value: "LE" },
	{ label: "Lecco", value: "LC" },
	{ label: "Livorno", value: "LI" },
	{ label: "Lodi", value: "LO" },
	{ label: "Lucca", value: "LU" },
	{ label: "Macerata", value: "MC" },
	{ label: "Mantova", value: "MN" },
	{ label: "Massa-Carrara", value: "MS" },
	{ label: "Matera", value: "MT" },
	{ label: "Messina", value: "ME" },
	{ label: "Milano", value: "MI" },
	{ label: "Modena", value: "MO" },
	{ label: "Monza e della Brianza", value: "MB" },
	{ label: "Napoli", value: "NA" },
	{ label: "Novara", value: "NO" },
	{ label: "Nuoro", value: "NU" },
	{ label: "Ogliastra", value: "OG" },
	{ label: "Olbia-Tempio", value: "OT" },
	{ label: "Oristano", value: "OR" },
	{ label: "Padova", value: "PD" },
	{ label: "Palermo", value: "PA" },
	{ label: "Parma", value: "PR" },
	{ label: "Pavia", value: "PV" },
	{ label: "Perugia", value: "PG" },
	{ label: "Pesaro e Urbino", value: "PU" },
	{ label: "Pescara", value: "PE" },
	{ label: "Piacenza", value: "PC" },
	{ label: "Pisa", value: "PI" },
	{ label: "Pistoia", value: "PT" },
	{ label: "Pordenone", value: "PN" },
	{ label: "Potenza", value: "PZ" },
	{ label: "Prato", value: "PO" },
	{ label: "Ragusa", value: "RG" },
	{ label: "Ravenna", value: "RA" },
	{ label: "Reggio Calabria", value: "RC" },
	{ label: "Reggio Emilia", value: "RE" },
	{ label: "Rieti", value: "RI" },
	{ label: "Rimini", value: "RN" },
	{ label: "Roma", value: "RM" },
	{ label: "Rovigo", value: "RO" },
	{ label: "Salerno", value: "SA" },
	{ label: "Sassari", value: "SS" },
	{ label: "Savona", value: "SV" },
	{ label: "Siena", value: "SI" },
	{ label: "Siracusa", value: "SR" },
	{ label: "Sondrio", value: "SO" },
	{ label: "Sud Sardegna", value: "SU" },
	{ label: "Taranto", value: "TA" },
	{ label: "Teramo", value: "TE" },
	{ label: "Terni", value: "TR" },
	{ label: "Torino", value: "TO" },
	{ label: "Trapani", value: "TP" },
	{ label: "Trento", value: "TN" },
	{ label: "Treviso", value: "TV" },
	{ label: "Trieste", value: "TS" },
	{ label: "Udine", value: "UD" },
	{ label: "Varese", value: "VA" },
	{ label: "Venezia", value: "VE" },
	{ label: "Verbano-Cusio-Ossola", value: "VB" },
	{ label: "Vercelli", value: "VC" },
	{ label: "Verona", value: "VR" },
	{ label: "Vibo Valentia", value: "VV" },
	{ label: "Vicenza", value: "VI" },
	{ label: "Viterbo", value: "VT" },
];
