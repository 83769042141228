import {
	Button,
	Image,
	Label,
	Text,
	View,
	useAuthenticator,
	PasswordField,
	Input,
	Divider,
	Link,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import logo from "../assets/Logos/cosmogas-logo-md.png";
import React, { useContext, useEffect, useState } from "react";
import { strings } from "../strings";
import { signOut } from "aws-amplify/auth";
import { Context } from "../contexts/context";
import BasicLayout from "../components/basicLayout";
import { fetchAuthSession } from "aws-amplify/auth";

const Profile = () => {
	const { user } = useContext(Context);
	const [isRedirecting, setIsRedirecting] = useState(false);
	const [links, setLinks] = useState([]);

	useEffect(() => {
		async function fetchSession() {
			const session = await fetchAuthSession({
				forceRefresh: true,
			});

			if (session?.tokens?.idToken) {
				const idToken = session?.tokens?.idToken.toString();
				const tokenPayload = JSON.parse(atob(idToken.split(".")[1]));

				setLinks(tokenPayload["cognito:groups"] ?? []);
			}
		}

		fetchSession();
		const queryParams = new URLSearchParams(window.location.search);

		if (queryParams.get("redirect_uri")) {
			setIsRedirecting(true);
		}
	}, [window.location.href]);

	const availablePoints = {
		accesso_documentale: (
			<Link
				href={I18n.get("Account activation success portale link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success portale label")}
			</Link>
		),
		accesso_www: (
			<Link
				href={I18n.get("Account activation success website link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success website label")}
			</Link>
		),
		accesso_cosmoclub: (
			<Link
				href={I18n.get("Account activation success cosmoclub link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success cosmoclub label")}
			</Link>
		),
		accesso_acquacalda: (
			<Link
				href={I18n.get("Account activation success acquacalda link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success acquacalda label")}
			</Link>
		),
		accesso_ricambi: (
			<Link
				href={I18n.get("Account activation success ricambi link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success ricambi label")}
			</Link>
		),
	};

	return (
		<BasicLayout>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				<Text
					headingLevel={1}
					marginBottom={"20px"}
					textAlign={"center"}
				>
					{`${I18n.get("Profile hello")} `}
					<b>{user?.email}</b>
					{"!"}
					<br></br>
				</Text>
				{isRedirecting && (
					<Text
						headingLevel={2}
						marginBottom={"20px"}
						textAlign={"center"}
					>
						{I18n.get("Profile redirecting")}
					</Text>
				)}
				<Text
					as="p"
					fontSize="16px"
					fontWeight="500"
					lineHeight="24px"
					textAlign={"center"}
					color="#4A5C7F"
					maxWidth="350px"
					marginInline="auto"
					dangerouslySetInnerHTML={{
						__html:
							links?.length > 0
								? I18n.get("Profile description")
								: I18n.get(
										"Account activation success description"
								  ),
					}}
				></Text>
				{links?.length > 0 && (
					<ul
						style={{
							listStyleType: "none",
							padding: 0,
							marginBottom: "32px",
							display: "flex",
							alignItems: "stretch",
							flexDirection: "column",
							gap: "8px",
						}}
					>
						{links?.map((link) => {
							return <li key={link}>{availablePoints[link]}</li>;
						})}
					</ul>
				)}
				<Button
					style={{
						width: "fit-content",
						margin: "0 auto",
					}}
					onClick={async () => {
						await signOut();
						window.location.reload();
					}}
				>
					Logout
				</Button>
			</div>
		</BasicLayout>
	);
};

export default Profile;
