import { Button, Label, Text, View, Input, Link } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import React, { useState, useEffect, useContext } from "react";
import { Context } from "../contexts/context";
import { resetPassword } from "aws-amplify/auth";
import BasicLayout from "../components/basicLayout";

const ForgotPassword = () => {
	const { user, updateUserEmail } = useContext(Context);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [isErrorVisible, setIsErrorVisible] = useState(false);

	useEffect(() => {
		setIsErrorVisible(error !== "" && error !== undefined);
	}, [error]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		setError("");
		try {
			resetPassword({
				username: user.email,
				options: {
					authFlowType: "USER_PASSWORD_AUTH",
				},
			})
				.then((e) => {
					window.location.replace("/confirmForgotPassword");
					setIsLoading(false);
				})
				.catch((e) => {
					setError(e.message);
					setIsLoading(false);
				});
		} catch (e) {
			setIsLoading(false);
			setError(e.message);
		}
	};

	return (
		<BasicLayout id={"reset-password"}>
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				<div
					style={{
						width: "56px",
						height: "56px",
						borderRadius: "12px",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						border: "1px solid #D2D8E5",
						margin: "auto auto 24px auto",
						backgroundColor: "white",
					}}
				>
					<svg
						width="28"
						height="28"
						viewBox="0 0 28 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M2.33301 8.16669L11.8587 14.8347C12.6301 15.3747 13.0158 15.6446 13.4353 15.7492C13.8059 15.8416 14.1935 15.8416 14.564 15.7492C14.9836 15.6446 15.3692 15.3747 16.1406 14.8347L25.6663 8.16669M7.93301 23.3334H20.0663C22.0265 23.3334 23.0066 23.3334 23.7553 22.9519C24.4139 22.6163 24.9493 22.0809 25.2849 21.4223C25.6663 20.6736 25.6663 19.6935 25.6663 17.7334V10.2667C25.6663 8.3065 25.6663 7.32641 25.2849 6.57772C24.9493 5.91915 24.4139 5.38372 23.7553 5.04816C23.0066 4.66669 22.0265 4.66669 20.0663 4.66669H7.93301C5.97282 4.66669 4.99273 4.66669 4.24404 5.04816C3.58547 5.38372 3.05004 5.91915 2.71448 6.57772C2.33301 7.32641 2.33301 8.3065 2.33301 10.2667V17.7334C2.33301 19.6935 2.33301 20.6736 2.71448 21.4223C3.05004 22.0809 3.58547 22.6163 4.24404 22.9519C4.99273 23.3334 5.97282 23.3334 7.93301 23.3334Z"
							stroke="#3D4A67"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<View textAlign="center">
					<Text
						as="h2"
						fontSize="30px"
						fontWeight="600"
						lineHeight="30px"
						marginBottom="12px"
					>
						{I18n.get("Forgot password title")}
					</Text>
					<Text
						as="p"
						fontSize="16px"
						fontWeight="500"
						lineHeight="24px"
						marginBottom="32px"
						color="#4A5C7F"
					>
						{I18n.get("Forgot password description")}
					</Text>
				</View>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "6px",
						marginBottom: "24px",
					}}
				>
					<Label htmlFor="email">
						{I18n.get("Forgot password email label")}
					</Label>
					<Input
						id="username"
						name="username"
						type="email"
						placeholder={I18n.get(
							"Forgot password email placeholder"
						)}
						value={user.email}
						onChange={(e) => updateUserEmail(e.target.value.toLowerCase())}
					/>
				</div>
				{error && isErrorVisible ? (
					<div
						className="amplify-flex amplify-alert amplify-alert--error"
						role="alert"
						style={{
							marginBottom: "24px",
						}}
					>
						<span className="amplify-alert__icon">
							<span className="amplify-icon" aria-hidden="true">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
										fill="currentColor"
									></path>
								</svg>
							</span>
						</span>
						<div
							style={{
								flex: "1 1 0%",
							}}
						>
							<div className="amplify-alert__body">
								<span>{I18n.get(error)}</span>
							</div>
						</div>
						<button
							className="amplify-button amplify-field-group__control amplify-button--link amplify-alert__dismiss"
							type="button"
							onClick={() => setIsErrorVisible(false)}
						>
							<span className="amplify-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
										fill="currentColor"
									></path>
								</svg>
							</span>
						</button>
					</div>
				) : null}
				<Button
					className="amplify-button amplify-field-group__control amplify-button--primary"
					type="submit"
					isLoading={isLoading}
				>
					{I18n.get("Forgot password submit")}
				</Button>
				<Link
					href="/"
					className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
					style={{
						display: "flex",
						marginInline: "auto",
						alignItems: "center",
						gap: "8px",
						marginTop: "32px",
					}}
				>
					<svg
						width="21"
						height="20"
						viewBox="0 0 21 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.3337 10H4.66699M4.66699 10L10.5003 15.8333M4.66699 10L10.5003 4.16666"
							stroke="#2B64F6"
							strokeWidth="1.66667"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span>{I18n.get("Back to login")}</span>
				</Link>
			</form>
		</BasicLayout>
	);
};

export default ForgotPassword;
