import React, { useContext, useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify/utils";
import { strings } from "./strings";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ResetPasswordSuccess from "./routes/resetPasswordSuccess";
import SignIn from "./routes/signIn";
import SignUp from "./routes/signUp";
import ForgotPassword from "./routes/forgotPassword";
import ConfirmForgotPassword from "./routes/confirmForgotPassword";
import Logout from "./routes/logout";
import Profile from "./routes/profile";
import { fetchAuthSession } from "aws-amplify/auth";
import { Context } from "./contexts/context";
import SignUpSuccess from "./routes/signUpSuccess";
import AccountActivationSuccess from "./routes/accountActivationSuccess";
import AccountActivation from "./routes/accountActivation";
import awsconfig from "./aws-exports";

I18n.putVocabularies(strings);

const App = () => {
	const { user, updateUserEmail } = useContext(Context);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		const checkAuthState = async () => {
			const session = await fetchAuthSession({
				forceRefresh: true,
			});

			if (session?.tokens?.idToken) {
				updateUserEmail(session?.tokens?.signInDetails?.loginId);
				setIsAuthenticated(true);
			} else {
				setIsAuthenticated(false);
			}

			const queryParams = new URLSearchParams(window.location.search);

			const redirect_uri = queryParams.get("redirect_uri");
			const state = queryParams.get("state");
			const id_token = session?.tokens?.idToken.toString();
			const access_token = session?.tokens?.accessToken?.toString();

			// TODO: Refactor ---> Non penso sia best practice...
			const refresh_token = localStorage.getItem(
				`CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.${session?.tokens?.signInDetails?.loginId}.refreshToken`
			);

			//Switch to signup
			if (redirect_uri && id_token) {
				const redirect_uri_url = new URL(redirect_uri);
				redirect_uri_url.searchParams.append("id_token", id_token);
				redirect_uri_url.searchParams.append(
					"access_token",
					access_token
				);
				redirect_uri_url.searchParams.append(
					"refresh_token",
					refresh_token
				);
				redirect_uri_url.searchParams.append("state", state);
				window.location.replace(redirect_uri_url.toString());
			}
		};

		checkAuthState();
	}, [window.location]);

	return (
		<Router>
			{isAuthenticated ? (
				<Routes>
					<Route
						path="/accountActivationSuccess"
						element={<AccountActivationSuccess />}
					/>
					<Route path="*" element={<Profile />} />
				</Routes>
			) : (
				<Routes>
					<Route
						path="/forgotPassword"
						element={<ForgotPassword />}
					/>
					<Route
						path="/confirmForgotPassword"
						element={<ConfirmForgotPassword />}
					/>
					<Route
						path="/resetPasswordSuccess"
						element={<ResetPasswordSuccess />}
					/>
					<Route
						path="/accountActivation"
						element={<AccountActivation />}
					/>
					<Route path="/signUp" element={<SignUp />} />
					<Route path="/signUpSuccess" element={<SignUpSuccess />} />
					<Route path="/logout" element={<Logout />} />
					<Route path="*" element={<SignIn />} />
				</Routes>
			)}
		</Router>
	);
};

export default App;
