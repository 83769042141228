import {
	Button,
	Image,
	Label,
	Text,
	View,
	PasswordField,
	Input,
	Link,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import logo from "../assets/Logos/cosmogas-logo-md.png";
import React, { useState, useEffect, useContext } from "react";
import { strings } from "../strings";
import CustomSelect from "../components/customSelect";
import { Context } from "../contexts/context";
import { signIn, fetchAuthSession } from "aws-amplify/auth";
import { languages } from "../consts";
import { useNavigate } from "react-router-dom";

I18n.putVocabularies(strings);

const SignIn = () => {
	const { user, updateUserEmail, setTempPassword } = useContext(Context);
	const [currentStep, setCurrentStep] = useState(0);
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [isErrorVisible, setIsErrorVisible] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		setIsErrorVisible(error !== "" && error !== undefined);
	}, [error]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (currentStep === 1) {
			setIsLoading(true);
			setError("");
			try {
				signIn({
					username: user.email,
					password: password,
					options: {
						authFlowType: "USER_PASSWORD_AUTH",
					},
				})
					.then(async (e) => {
						const queryParams = new URLSearchParams(
							window.location.search
						);

						const redirect_uri = queryParams.get("redirect_uri");
						const state = queryParams.get("state");
						if (
							e?.nextStep?.signInStep ===
							"CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
						) {
							setTempPassword(password);
							navigate(
								`/accountActivation?${
									redirect_uri
										? `redirect_uri=${redirect_uri}`
										: ""
								}${state ? `&state=${state}` : ""}`,
								{ shallow: true }
							);
						} else if (e?.nextStep?.signInStep === "RESET_PASSWORD") {
							navigate(
								`/forgotPassword?${
									redirect_uri
										? `redirect_uri=${redirect_uri}`
										: ""
								}${state ? `&state=${state}` : ""}`,
								{ shallow: true }
							);
						} else {
							window.location.reload();
						}

						setIsLoading(false);
					})
					.catch((e) => {
						setError(e.message);
						setIsLoading(false);
					});
			} catch (e) {
				setIsLoading(false);
				setError(e.message);
			}
		} else {
			setCurrentStep(1);
		}
	};

	const mediaMatch = window.matchMedia("(min-width: 998px)");
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e) => setMatches(e.matches);
		mediaMatch.addListener(handler);
		return () => mediaMatch.removeListener(handler);
	});

	const signInContainerStyle = {
		container: (isRowBased) => ({
			display: isRowBased ? "grid" : "flex",
			gridTemplateColumns: "1fr 2fr",
			flexDirection: isRowBased ? "row" : "column-reverse",
			alignItems: "stretch",
			justifyContent: "start",
			width: "100%",
			minHeight: "100vh",
			overflow: "hidden",
		}),
	};

	const signInStyle = {
		container: (isRowBased) => ({
			position: "relative",
			height: isRowBased ? "100%" : "auto",
			display: "flex",
			flexDirection: "column",
			alignItems: "stretch",
			justifyContent: isRowBased ? "center" : "space-between",
			flexGrow: "1",
		}),
	};

	const authContainerStyle = {
		container: (isRowBased) => ({
			paddingInline: isRowBased ? "80px" : "24px",
			paddingBlock: isRowBased ? "0" : "24px",
			position: "relative",
			zIndex: "10",
		}),
	};

	const footerStyle = {
		container: (isRowBased) => ({
			position: isRowBased ? "absolute" : "relative",
			bottom: 0,
			left: 0,
			right: 0,
			paddingBlock: "10px",
			paddingInline: isRowBased ? "26px" : "10px",
			backgroundColor: "#EBEDF3",
			fontSize: "10px",
			color: "#354057",
		}),
	};

	const bgImageStyle = {
		container: (isRowBased) => ({
			position: "absolute",
			inset: 0,
			objectFit: "cover",
			objectPosition: "left",
			width: "100%",
			height: "100%",
			display: isRowBased ? "block" : "none",
		}),
	};

	const bgImageMobileStyle = {
		container: (isRowBased) => ({
			width: "auto",
			minWidth: "100%",
			display: isRowBased ? "none" : "block",
		}),
	};

	return (
		<div id="signin" style={signInContainerStyle.container(matches)}>
			<div style={signInStyle.container(matches)}>
				<div style={authContainerStyle.container(matches)}>
					<form onSubmit={handleSubmit}>
						<View textAlign="center">
							<Image
								alt="Cosmogas logo"
								src={logo}
								style={{ maxWidth: 250 }}
							/>
							<Text
								as="h1"
								fontSize="24px"
								fontWeight="600"
								lineHeight="133%"
								marginTop="40px"
								marginBottom="16px"
							>
								{I18n.get("Sign in title")}
							</Text>
						</View>
						{currentStep === 1 ? (
							<>
								<Button
									onClick={() => {
										setPassword("");
										setCurrentStep(0);
									}}
									style={{
										display: "flex",
										alignItems: "center",
										gap: "4px",
										borderRadius: "999rem",
										backgroundColor: "#EFF4FF",
										border: "1px solid #BFD5FE",
										color: "#1C3BD9",
										paddingBlock: "2px",
										paddingInline: "8px",
										marginBottom: "20px",
										fontWeight: 600,
									}}
								>
									<svg
										width="12"
										height="13"
										viewBox="0 0 12 13"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.5 9.46378L4.5 6.46378L7.5 3.46378"
											stroke="#1C3BD9"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									<span>{user.email}</span>
								</Button>
								<PasswordField
									autoComplete="new-password"
									label={I18n.get("Sign in password label")}
									id="password"
									name="password"
									placeholder={I18n.get(
										"Sign in password placeholder"
									)}
									isRequired
									marginBottom="20px"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</>
						) : null}
						{currentStep === 0 ? (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "6px",
									marginBottom: "24px",
								}}
							>
								<Label htmlFor="email">
									{I18n.get("Sign in email label")}
								</Label>
								<Input
									id="username"
									name="username"
									type="email"
									isRequired
									placeholder={I18n.get(
										"Sign in email placeholder"
									)}
									value={user.email}
									onChange={(e) =>
										updateUserEmail(e.target.value?.toLowerCase())
									}
								/>
							</div>
						) : null}
						{isErrorVisible ? (
							<div
								className="amplify-flex amplify-alert amplify-alert--error"
								role="alert"
								style={{
									marginBottom: "24px",
								}}
							>
								<span className="amplify-alert__icon">
									<span
										className="amplify-icon"
										aria-hidden="true"
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
												fill="currentColor"
											></path>
										</svg>
									</span>
								</span>
								<div
									style={{
										flex: "1 1 0%",
									}}
								>
									<div className="amplify-alert__body">
										<span>{I18n.get(error)}</span>
										{/* Object.keys(validationErrors)?.map(
											(err) => {
												return (
													<span>
														{I18n.get(
															validationErrors[
															err
															]
														)}
													</span>
												);
											}
										) */}
									</div>
								</div>
								<button
									className="amplify-button amplify-field-group__control amplify-button--link amplify-alert__dismiss"
									type="button"
									onClick={() => setIsErrorVisible(false)}
								>
									<span className="amplify-icon">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
												fill="currentColor"
											></path>
										</svg>
									</span>
								</button>
							</div>
						) : null}
						<Button
							variation="primary"
							isFullWidth
							type="submit"
							isLoading={isLoading}
						>
							{currentStep === 0
								? I18n.get("Sign in foreward")
								: I18n.get("Sign in submit")}
						</Button>
						{currentStep === 0 ? (
							<Text
								as={"div"}
								color={"#4A5C7F"}
								fontWeight={400}
								width={"fit-content"}
								textAlign={"center"}
								marginInline={"auto"}
								marginTop={"32px"}
							>
								{I18n.get("Sign in not registered")}{" "}
								<Link
									href="/signUp"
									//href="https://www.cosmogas.com/richiedi-iscrizione-allarea-professionisti/"
									//target="_blank"
									style={{
										color: "#1C3BD9",
										fontWeight: 600,
									}}
								>
									{I18n.get("Sign in request registration")}
								</Link>
							</Text>
						) : null}
						{currentStep === 1 ? (
							<a
								href="/forgotPassword"
								className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
								style={{
									display: "block",
									margin: "16px auto 0 auto",
									textAlign: "center",
								}}
							>
								<span>
									{I18n.get("Sign in forgot password")}
								</span>
							</a>
						) : null}
						<CustomSelect options={languages} />
					</form>
				</div>
				<footer style={footerStyle.container(matches)}>
					<span
						style={{
							display: "block",
						}}
					>
						{I18n.get("Footer text")}
					</span>
					<span
						style={{
							display: "block",
						}}
					>
						<Link
							href={I18n.get("Footer privacy link")}
							target="_blank"
							color={"#4A5C7F"}
						>
							{"Privacy"}
						</Link>
						{" | "}
						<Link
							href={I18n.get("Footer cookie link")}
							target="_blank"
							color={"#4A5C7F"}
						>
							{"Cookie"}
						</Link>
					</span>
				</footer>
			</div>
			<div
				style={{
					position: "relative",
					minHeight: "265px",
				}}
			>
				<Image
					style={bgImageStyle.container(matches)}
					src="/images/albero_desktop.jpg"
					alt="background"
					objectFit={"cover"}
					objectPosition={"center top"}
				/>
				<Image
					style={bgImageMobileStyle.container(matches)}
					src="/images/albero_mobile.png"
					alt="background"
					objectFit={"cover"}
					objectPosition={"center top"}
				/>
			</div>
		</div>
	);
};

export default SignIn;
