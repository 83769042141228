import * as React from 'react';
import { useEffect, useState, FC, useMemo } from 'react';

const CircleCountDown = ({
    time,
    size,
    stroke,
    onComplete,
    strokeWidth,
    strokeLinecap = 'round',
}) => {
    const radius = size / 2;
    const milliseconds = time * 1000;
    const circumference = size * Math.PI;

    const [countdown, setCountdown] = useState(milliseconds);

    const seconds = (countdown / 1000).toFixed();

    const strokeDashoffset =
        circumference - (countdown / milliseconds) * circumference;

    useEffect(() => {
        const interval = setInterval(() => {
            if (countdown > 0) {
                setCountdown(countdown - 10);
            } else {
                clearInterval(interval);
                onComplete && onComplete();
            }
        }, 10);
        return () => clearInterval(interval);
    }, [countdown]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        }}>
            <label style={{
                position: 'absolute',
                color: stroke
            }}>{seconds}</label>
            <div style={{
                transform: 'rotate(90deg)',
                width: size,
                height: size
            }}>
                <svg style={{
                    transform: 'scale(-1,1)',
                    overflow: 'visible'
                }} width={size} height={size}>
                    <circle
                        fill="none"
                        r={radius}
                        cx={radius}
                        cy={radius}
                        stroke={stroke}
                        strokeWidth={strokeWidth}
                        strokeLinecap={strokeLinecap}
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                    />
                </svg>
            </div>
        </div>
    );
};

export default CircleCountDown;
