import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify/utils";

const HSForm = (props) => {
	const { region, portalId, formId } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [isErrorVisible, setIsErrorVisible] = useState(false);

	useEffect(() => {
		setIsErrorVisible(error !== "" && error !== undefined);
	}, [error]);

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://js.hsforms.net/forms/shell.js";
		document.body.appendChild(script);

		script.addEventListener("load", () => {
			// @TS-ignore
			if (window.hbspt) {
				// @TS-ignore
				window.hbspt.forms.create({
					region: region,
					portalId: portalId,
					formId: formId,
					target: "#hubspotForm",
					onFormReady: () => {
						setLoading(false);
						const formInputs =
							document.querySelectorAll("#hubspotForm input");
						formInputs.forEach((input) => {
							input.addEventListener("change", () => {
								const errors_label = document.querySelectorAll(
									"#hubspotForm fieldset .hs-error-msgs li label"
								);

								const errors = [];

								for (let i = 0; i < errors_label.length; i++) {
									errors.push(errors_label[i].innerText);
								}

								if (errors.length > 0) {
									setError([...new Set(errors)]?.join("\n"));
									setIsErrorVisible(true);
								}
							});
						});

						document
							.getElementById("hubspotForm")
							.addEventListener("submit", (e) => {
								const errors_label = document.querySelectorAll(
									"#hubspotForm fieldset .hs-error-msgs li label"
								);

								const errors = [];

								for (let i = 0; i < errors_label.length; i++) {
									errors.push(errors_label[i].innerText);
								}

								if (errors.length > 0) {
									setError([...new Set(errors)]?.join("\n"));
									setIsErrorVisible(true);
								}
							});
					},
				});
			}
		});
	}, [formId]);

	return (
		<div>
			<div id="hubspotForm"></div>
			{isErrorVisible ? (
				<div
					className="amplify-flex amplify-alert amplify-alert--error"
					role="alert"
					style={{
						marginBlock: "24px",
					}}
				>
					<span className="amplify-alert__icon">
						<span className="amplify-icon" aria-hidden="true">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
									fill="currentColor"
								></path>
							</svg>
						</span>
					</span>
					<div
						style={{
							flex: "1 1 0%",
						}}
					>
						<div className="amplify-alert__body">
							<span>{I18n.get(error)}</span>
						</div>
					</div>
					<button
						className="amplify-button amplify-field-group__control amplify-button--link amplify-alert__dismiss"
						type="button"
						onClick={() => setIsErrorVisible(false)}
					>
						<span className="amplify-icon">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
									fill="currentColor"
								></path>
							</svg>
						</span>
					</button>
				</div>
			) : null}
		</div>
	);
};

export default HSForm;
