import { Text, View, Link } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import React from "react";
import BasicLayout from "../components/basicLayout";

const SignUpSuccess = () => {
	return (
		<BasicLayout>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				<div
					style={{
						width: "56px",
						height: "56px",
						borderRadius: "12px",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						border: "1px solid #D2D8E5",
						margin: "auto auto 24px auto",
						backgroundColor: "white",
					}}
				>
					<svg
						width="28"
						height="28"
						viewBox="0 0 28 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M23.3337 7L10.5003 19.8333L4.66699 14"
							stroke="#3D4A67"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<View textAlign="center">
					<Text
						as="h2"
						fontSize="30px"
						fontWeight="600"
						lineHeight="30px"
						marginBottom="12px"
						maxWidth="350px"
						marginInline="auto"
					>
						{I18n.get("Sign up success title")}
					</Text>
					<Text
						as="p"
						fontSize="16px"
						fontWeight="500"
						lineHeight="24px"
						marginBottom="32px"
						color="#4A5C7F"
						maxWidth="350px"
						marginInline="auto"
						dangerouslySetInnerHTML={{
							__html: I18n.get("Sign up success description"),
						}}
					></Text>
					<Link
						href={I18n.get("Sign up success link")}
						target="_blank"
						className="amplify-button amplify-button--primary"
						display="block"
						maxWidth="350px"
						marginInline="auto"
						color="white"
					>
						{I18n.get("Sign up success button label")}
					</Link>
				</View>
			</div>
		</BasicLayout>
	);
};

export default SignUpSuccess;
