import {
	Button,
	Image,
	Label,
	Text,
	View,
	useAuthenticator,
	PasswordField,
	Input,
	Divider,
	Link,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import logo from "../assets/Logos/cosmogas-logo-md.png";
import React, { useContext, useState, useEffect } from "react";
import { strings } from "../strings";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { Context } from "../contexts/context";
import Code from "../components/codeInput";
import CountDownTimer from "../components/countDownTimer";
import PasswordInputs from "../components/passwordInputs";
import BasicLayout from "../components/basicLayout";

I18n.putVocabularies(strings);

const ConfirmForgotPassword = () => {
	const { user } = useContext(Context);

	const [code, setCode] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [isErrorVisible, setIsErrorVisible] = useState(false);
	const [allValid, setAllValid] = useState(false);
	const [canResendCode, setCanResendCode] = useState(true);

	useEffect(() => {
		setIsErrorVisible(error !== "" && error !== undefined);
	}, [error]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError("");

		if (allValid) {
			setIsLoading(true);
			try {
				confirmResetPassword({
					username: user.email,
					confirmationCode: code,
					newPassword: newPassword,
				})
					.then((e) => {
						window.location.replace("/resetPasswordSuccess");
						setIsLoading(false);
					})
					.catch((e) => {
						setError(e.message);
						setIsErrorVisible(true);
						setIsLoading(false);
					});
			} catch (e) {
				setIsLoading(false);
				setError(e.message);
				setIsErrorVisible(true);
			}
		} else {
			setError(I18n.get("Password does not meet requirements"));
			setIsErrorVisible(true)
		}
	};

	return (
		<BasicLayout>
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
					width: "min-content",
				}}
			>
				<div
					style={{
						width: "56px",
						height: "56px",
						borderRadius: "12px",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						border: "1px solid #D2D8E5",
						margin: "auto auto 24px auto",
						color: "#3D4A67",
						fontSize: "30px",
						fontWeight: "600",
						fontFamily: "'Inter', sans-serif",
						backgroundColor: "white",
					}}
				>
					1
				</div>
				<View textAlign="center">
					<Text
						as="h2"
						fontSize="30px"
						fontWeight="600"
						lineHeight="30px"
						marginBottom="12px"
					>
						{I18n.get("Confirm reset password code title")}
					</Text>
					<Text
						as="p"
						fontSize="16px"
						fontWeight="400"
						lineHeight="24px"
						marginBottom="32px"
						color="#4A5C7F"
					>
						{I18n.get("Confirm reset password code description")}
						<Text as="span" color="#4A5C7F" fontWeight="600">
							{user.email}
						</Text>
					</Text>
				</View>
				<Code code={code} setCode={setCode} />
				<Button
					className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
					style={{
						display: "block",
						margin: "16px auto 0 auto",
						textAlign: "center",
					}}
					disabled={!canResendCode}
					onClick={() => {
						setCanResendCode(false);
						resetPassword({
							username: user.email,
						})
							.then((e) => {
								setIsLoading(false);
							})
							.catch((e) => {
								setError(e.message);
								setIsLoading(false);
							});
					}}
				>
					<span>
						{I18n.get("Confirm reset password code resend")}
					</span>
				</Button>
				{!canResendCode ? (
					<CountDownTimer
						time={60}
						stroke={"#2B64F6"}
						strokeWidth={2}
						size={40}
						onComplete={() => setCanResendCode(true)}
					/>
				) : null}
				<hr
					style={{
						display: "block",
						width: "70%",
						height: "1px",
						borderTop: "1px solid #ABB8CE",
						borderBottom: "none",
						marginBlock: "32px",
					}}
				></hr>
				<View textAlign="center">
					<div
						style={{
							width: "56px",
							height: "56px",
							borderRadius: "12px",
							boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							border: "1px solid #D2D8E5",
							margin: "auto auto 24px auto",
							color: "#3D4A67",
							fontSize: "30px",
							fontWeight: "600",
							fontFamily: "'Inter', sans-serif",
							backgroundColor: "white",
						}}
					>
						2
					</div>
					<Text
						as="h2"
						fontSize="30px"
						fontWeight="600"
						lineHeight="30px"
						marginBottom="12px"
					>
						{I18n.get("Confirm reset password password title")}
					</Text>
					<Text
						as="p"
						fontSize="16px"
						fontWeight="400"
						lineHeight="24px"
						marginBottom="32px"
						color="#4A5C7F"
					>
						{I18n.get("Confirm reset password next step")}
					</Text>
				</View>
				<PasswordInputs
					newPassword={newPassword}
					setNewPassword={setNewPassword}
					confirmNewPassword={confirmNewPassword}
					setConfirmNewPassword={setConfirmNewPassword}
					allValid={allValid}
					setAllValid={setAllValid}
				/>
				{isErrorVisible ? (
					<div
						className="amplify-flex amplify-alert amplify-alert--error"
						role="alert"
						style={{
							marginBottom: "24px",
						}}
					>
						<span className="amplify-alert__icon">
							<span className="amplify-icon" aria-hidden="true">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
										fill="currentColor"
									></path>
								</svg>
							</span>
						</span>
						<div
							style={{
								flex: "1 1 0%",
							}}
						>
							<div className="amplify-alert__body">
								<span>{I18n.get(error)}</span>
								{/* {Object.keys(validationErrors)?.map(
											(err) => {
												return (
													<span>
														{I18n.get(
															validationErrors[err]
														)}
													</span>
												);
											}
										)} */}
							</div>
						</div>
						<button
							className="amplify-button amplify-field-group__control amplify-button--link amplify-alert__dismiss"
							type="button"
							onClick={() => setIsErrorVisible(false)}
						>
							<span className="amplify-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
										fill="currentColor"
									></path>
								</svg>
							</span>
						</button>
					</div>
				) : null}
				<Button
					className="amplify-button amplify-field-group__control amplify-button--primary"
					type="submit"
					isLoading={isLoading}
				>
					{I18n.get("Confirm reset password password submit")}
				</Button>
				<Link
					href="/"
					className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
					style={{
						display: "flex",
						marginInline: "auto",
						alignItems: "center",
						gap: "8px",
						marginTop: "32px",
					}}
				>
					<svg
						width="21"
						height="20"
						viewBox="0 0 21 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.3337 10H4.66699M4.66699 10L10.5003 15.8333M4.66699 10L10.5003 4.16666"
							stroke="#2B64F6"
							strokeWidth="1.66667"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span>{I18n.get("Back to login")}</span>
				</Link>
			</form>
		</BasicLayout>
	);
};

export default ConfirmForgotPassword;
