import { Image } from "@aws-amplify/ui-react";
import logo from "../assets/Logos/cosmogas-logo-md.png";

const BasicLayout = ({ children, id }) => {
	return (
		<div
			id={id || ""}
			style={{
				position: "relative",
				width: "100%",
				minHeight: "100vh",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				paddingBottom: "70px",
			}}
		>
			<Image
				style={{
					width: "241.5px",
					height: "70px",
					margin: "56px auto",
				}}
				src={logo}
				alt="LOGO Cosmogas"
			/>
			<div
				style={{
					position: "relative",
					width: "100%",
				}}
			>
				<div
					style={{
						maxWidth: "472px",
						margin: "auto",
						padding: "24px",
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export default BasicLayout;
