import React, { useRef, useState, useEffect } from "react";
import { PasswordField, Text } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";

export default function PasswordInputs({
	newPassword,
	setNewPassword,
	confirmNewPassword,
	setConfirmNewPassword,
	allValid,
	setAllValid,
}) {
	const [containsUL, setContainsUL] = useState(false); // uppercase letter
	const [containsLL, setContainsLL] = useState(false); // lowercase letter
	const [containsN, setContainsN] = useState(false); // number
	const [containsSC, setContainsSC] = useState(false); // special character
	const [contains8C, setContains8C] = useState(false); // min 8 characters
	const [passwordMatch, setPasswordMatch] = useState(true); // password match

	const mustContainData = [
		[
			I18n.get("Confirm reset password password list password match"),
			passwordMatch,
		],
		[
			I18n.get("Confirm reset password password list minimum characters"),
			contains8C,
		],
		[
			I18n.get("Confirm reset password password list uppercase"),
			containsUL,
		],
		[
			I18n.get("Confirm reset password password list lowercase"),
			containsLL,
		],
		[I18n.get("Confirm reset password password list numbers"), containsN],
		[
			I18n.get("Confirm reset password password list characters"),
			containsSC,
		],
	];

	const validatePassword = () => {
		// has uppercase letter
		if (newPassword.toLowerCase() != newPassword) setContainsUL(true);
		else setContainsUL(false);

		// has lowercase letter
		if (newPassword.toUpperCase() != newPassword) setContainsLL(true);
		else setContainsLL(false);

		// has number
		if (/\d/.test(newPassword)) setContainsN(true);
		else setContainsN(false);

		// has special character
		if (/[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(newPassword))
			setContainsSC(true);
		else setContainsSC(false);

		// has 8 characters
		if (newPassword.length >= 8) setContains8C(true);
		else setContains8C(false);

		if (newPassword === confirmNewPassword) setPasswordMatch(true);
		else setPasswordMatch(false);
	};

	useEffect(() => {
		// all validations passed
		if (
			[containsUL, containsLL, containsN, containsSC].filter((b) => !!b)
				.length >= 3 &&
			contains8C &&
			passwordMatch
		)
			setAllValid(true);
		else setAllValid(false);
	}, [
		containsUL,
		containsLL,
		containsN,
		containsSC,
		contains8C,
		passwordMatch,
	]);

	return (
		<>
			<PasswordField
				autoComplete="new-password"
				label={I18n.get(
					"Confirm reset password password newPassword label"
				)}
				id="password"
				name="password"
				placeholder={I18n.get(
					"Confirm reset password password newPassword placeholder"
				)}
				isRequired
				marginBottom="20px"
				value={newPassword}
				onChange={(e) => setNewPassword(e.target.value)}
				onKeyUp={validatePassword}
			/>
			<PasswordField
				autoComplete="current-password"
				label={I18n.get(
					"Confirm reset password password repeatPassword label"
				)}
				id="confirm_password"
				name="confirm_password"
				placeholder={I18n.get(
					"Confirm reset password password repeatPassword placeholder"
				)}
				isRequired
				value={confirmNewPassword}
				onChange={(e) => setConfirmNewPassword(e.target.value)}
				onKeyUp={validatePassword}
			/>
			<ul className="check-list">
				{mustContainData.slice(0, 2).map((data) => (
					<MustContainItem data={data} />
				))}
				<Text
					as="span"
					fontSize="12px"
					fontWeight="400"
					lineHeight="18px"
					color="#4A5C7F"
				>
					{I18n.get("Confirm reset password password list divider")}
				</Text>
				{mustContainData.slice(2).map((data) => (
					<MustContainItem data={data} />
				))}
			</ul>
		</>
	);
}

const MustContainItem = (props) => {
	const { data } = props;
	const label = data[0]; // name of item
	const meetsReq = data[1]; // boolean of whether or not req is satisfied

	// returns string of classes based on props aliased meetsReq
	const setClass = () => {
		const classArr = ["must-line"];
		if (meetsReq) classArr.push("cross-out");
		return classArr.join(" ");
	};

	return (
		<li className={setClass()}>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="20" height="20" rx="10" fill="#ABB8CE" />
				<path
					d="M6.25 10L8.75 12.5L13.75 7.5"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			<span>{label}</span>
		</li>
	);
};
