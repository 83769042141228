import React, { useRef, useState, useEffect, useContext } from "react";
import { I18n } from "aws-amplify/utils";
import { Context } from "../contexts/context";

export default function CustomSelect({ options }) {
	const { lang, updateLang } = useContext(Context);
	const [selectedOption, setSelectedOption] = useState();
	const [areOptionsOpen, setAreOptionsOpen] = useState(false);

	const select = useRef(null);

	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setAreOptionsOpen(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	useOutsideAlerter(select);

	useEffect(() => {
		updateLang(selectedOption?.value || "it");
	}, [selectedOption]);

	useEffect(() => {
		setSelectedOption(options?.filter((o) => o.value === lang)[0]);
	}, [lang]);

	return (
		<div ref={select} className="custom-select" role="select">
			<button
				className="custom-select-opener"
				type="button"
				onClick={() => setAreOptionsOpen((i) => !i)}
			>
				<div
					style={{
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "24px",
						display: "flex",
						alignItems: "center",
						color: "#30374A",
						gap: "10px",
					}}
				>
					{selectedOption?.icon}
					<label>{selectedOption?.label}</label>
				</div>
				<svg
					style={{
						rotate: areOptionsOpen ? "180deg" : "0deg",
						transition: "all .1s ease-in-out",
					}}
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5 7.96378L10 12.9638L15 7.96378"
						stroke="#5E7499"
						strokeWidth="1.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			{/* OPTIONS CONTAINER */}
			<div
				className={`custom-select-options-container ${
					areOptionsOpen ? "opened" : ""
				}`}
			>
				<div>
					{options?.map((o) => {
						return (
							<button
								key={o.label}
								className="custom-select-option"
								type="button"
								onClick={() => {
									setSelectedOption(o);
									setAreOptionsOpen(false);
								}}
							>
								{o?.icon}
								{o?.label}
							</button>
						);
					})}
				</div>
			</div>
		</div>
	);
}
