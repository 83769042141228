import {
	Button,
	Image,
	Label,
	Text,
	View,
	useAuthenticator,
	PasswordField,
	Input,
	Flex,
	Divider,
	Link,
	SelectField,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import logo from "../assets/Logos/cosmogas-logo-md.png";
import React, { useRef, useState, useEffect, useContext } from "react";
import { strings } from "../strings";
import CustomSelect from "../components/customSelect";
import { Context } from "../contexts/context";
import { signIn, fetchAuthSession } from "aws-amplify/auth";
import { languages, provinces, roles } from "../consts";
import HSForm from "../components/hsForm";

const SignUp = () => {
	const [isLoading, setIsLoading] = useState(true);

	const { lang } = useContext(Context);

	// Default form is ENGLISH
	const [hsFormId, setHsFormId] = useState("");

	useEffect(() => {
		console.log(lang);
		if (lang) {
			switch (lang) {
				case "it":
					setHsFormId((e) => "a99d8e83-05bb-4adb-91fd-a1ac43f63008");
					break;
				case "us/ca":
				case "en":
					setHsFormId((e) => "77df4c74-649e-48bd-8cc6-5152d0df44f7");
					break;
				case "fr":
					setHsFormId((e) => "ee88aa30-b28f-4e1e-8087-74aa84d34b56");
					break;
				case "es":
					setHsFormId((e) => "bde0f76c-d33e-480f-92f1-be1bf070c7d8");
					break;
				default:
					setHsFormId((e) => "77df4c74-649e-48bd-8cc6-5152d0df44f7");
					break;
			}

			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		console.log(lang);
		if (lang) {
			switch (lang) {
				case "it":
					setHsFormId((e) => "a99d8e83-05bb-4adb-91fd-a1ac43f63008");
					break;
				case "us/ca":
				case "en":
					setHsFormId((e) => "77df4c74-649e-48bd-8cc6-5152d0df44f7");
					break;
				case "fr":
					setHsFormId((e) => "ee88aa30-b28f-4e1e-8087-74aa84d34b56");
					break;
				case "es":
					setHsFormId((e) => "bde0f76c-d33e-480f-92f1-be1bf070c7d8");
					break;
				default:
					setHsFormId((e) => "77df4c74-649e-48bd-8cc6-5152d0df44f7");
					break;
			}

			setIsLoading(false);
		}
	}, [lang]);

	const mediaMatch = window.matchMedia("(min-width: 998px)");
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e) => setMatches(e.matches);
		mediaMatch.addListener(handler);
		return () => mediaMatch.removeListener(handler);
	});

	const signInContainerStyle = {
		container: (isRowBased) => ({
			display: isRowBased ? "grid" : "flex",
			gridTemplateColumns: "2fr 1fr",
			flexDirection: isRowBased ? "row" : "column-reverse",
			alignItems: "stretch",
			justifyContent: "start",
			width: "100%",
			minHeight: "100vh",
			overflow: "hidden",
		}),
	};

	const signInStyle = {
		container: (isRowBased) => ({
			position: "relative",
			height: isRowBased ? "100%" : "auto",
			display: "flex",
			flexDirection: "column",
			alignItems: "stretch",
			justifyContent: isRowBased ? "center" : "space-between",
			flexGrow: "1",
			paddingInline: isRowBased ? "80px" : "24px",
			paddingBlock: isRowBased ? "80px" : "24px",
		}),
	};

	const authContainerStyle = {
		container: (isRowBased) => ({
			paddingInline: isRowBased ? "40px" : "24px",
			paddingBlock: isRowBased ? "32px" : "24px",
			position: "relative",
			zIndex: "10",
			backgroundColor: "white",
			borderRadius: "12px",
			overflow: "hidden",
		}),
	};

	const footerStyle = {
		container: (isRowBased) => ({
			position: isRowBased ? "absolute" : "relative",
			bottom: 0,
			left: 0,
			right: 0,
			paddingBlock: "10px",
			paddingInline: isRowBased ? "26px" : "10px",
			backgroundColor: "#EBEDF3",
			fontSize: "10px",
			color: "#354057",
		}),
	};

	const bgImageStyle = {
		container: (isRowBased) => ({
			position: "absolute",
			inset: 0,
			objectFit: "cover",
			objectPosition: "left",
			width: "100%",
			height: "100%",
			display: isRowBased ? "block" : "none",
		}),
	};

	const bgImageMobileStyle = {
		container: (isRowBased) => ({
			width: "auto",
			minWidth: "100%",
			display: isRowBased ? "none" : "block",
		}),
	};

	return (
		<div id="signup" style={signInContainerStyle.container(matches)}>
			<div style={signInStyle.container(matches)}>
				<div style={authContainerStyle.container(matches)}>
					<Flex
						gap={"20px"}
						width={"100%"}
						justifyContent={"space-between"}
						alignItems={"center"}
						wrap={"wrap"}
					>
						<Image
							alt="Cosmogas logo"
							src={logo}
							style={{ width: "120px", height: "35px" }}
						/>
						<CustomSelect options={languages} />
					</Flex>
					<Text
						as="h1"
						fontSize="24px"
						fontWeight="600"
						lineHeight="133%"
						marginTop="40px"
					>
						{I18n.get("Sign up title")}
					</Text>
					<Text
						as="p"
						color={"#4A5C7F"}
						lineHeight="150%"
						marginTop="8px"
						marginBottom="16px"
					>
						{I18n.get("Sign up description")}
					</Text>
					{!isLoading && hsFormId ? (
						<HSForm
							key={hsFormId}
							region="eu1"
							portalId="6765943"
							formId={hsFormId}
						/>
					) : null}
					<Text
						color={"#4A5C7F"}
						fontWeight={400}
						width={"fit-content"}
						textAlign={"center"}
						marginInline={"auto"}
						marginTop={"32px"}
					>
						{I18n.get("Already registered")}{" "}
						<Link
							href="/"
							className=""
							style={{
								color: "#1C3BD9",
								fontWeight: 600,
							}}
						>
							{I18n.get("Sign in")}
						</Link>
					</Text>
				</div>
			</div>
			<div
				style={{
					position: "relative",
					minHeight: "265px",
				}}
			>
				<Image
					style={bgImageStyle.container(matches)}
					src="/images/albero_no_scritte.jpg"
					alt="background"
					objectFit={"cover"}
					objectPosition={"center top"}
				/>
				<Image
					style={bgImageMobileStyle.container(matches)}
					src="/images/albero_mobile.png"
					alt="background"
					objectFit={"cover"}
					objectPosition={"center top"}
				/>
			</div>
		</div>
	);
};

export default SignUp;
