import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify/utils";

const Context = React.createContext();

function ContextProvider({ children }) {
	const [user, setUser] = useState({
		email: "",
	});
	const [lang, setLang] = useState("");
	const [tempPassword, setTempPassword] = useState("");

	useEffect(() => {
		const userData = JSON.parse(sessionStorage.getItem("user"));

		if (userData) {
			setUser(userData);
		}
	}, []);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);

		const langData =
			queryParams.get("lang") ??
			JSON.parse(sessionStorage.getItem("lang")) ??
			"it";
		if (
			["it", "us/ca", "gb", "de", "fr", "es", "hu", "ro"].includes(
				langData
			)
		) {
			setLang(langData);
			I18n.setLanguage(langData);
		}
	}, []);

	useEffect(() => {
		sessionStorage.setItem("user", JSON.stringify(user));
	}, [user]);
	useEffect(() => {
		sessionStorage.setItem("lang", JSON.stringify(lang));
	}, [lang]);

	function updateUserEmail(email) {
		setUser({
			...user,
			email: email,
		});
	}

	function updateLang(lang) {
		setLang(lang);
		I18n.setLanguage(lang);
	}

	return (
		<Context.Provider
			value={{
				user,
				lang,
				tempPassword,
				updateUserEmail,
				updateLang,
				setTempPassword,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export { ContextProvider, Context };
